






import { Component, Vue } from "vue-property-decorator";
import KognifaiDataAnalysisWrapper from "@/components/knockoutWrappers/KognifaiDataAnalysisWrapper.vue";

@Component({
  components: {
    KognifaiDataAnalysisWrapper,
  },
})
export default class KognifaiDataAnalysis extends Vue {}
